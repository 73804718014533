
import { useEffect } from 'react';
import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Icon } from "src/templates"

const VideoContentArea = ({title, description, video, callToAction}) => {
    
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const NewlineText = (text) => {
  return text.split('\n').map((str, index) => <span key={index}>{str}<br/></span>);
 }

 function toggleVideo({ currentTarget: wrapper }) {
  let video = wrapper.querySelector('.video');
  let playpause = wrapper.querySelector('.playpause');
  if (video.paused) {
   video.play();
   playpause.setAttribute('hidden', '');
  } else {
   video.pause();
   playpause.removeAttribute('hidden');
  }
 }

 useEffect(() => {
  if (document.querySelectorAll('.wrapper') != null) {
   document.querySelectorAll('.wrapper')
    .forEach(wrapper => wrapper.addEventListener('click', toggleVideo));
  }
 }, []);
   
 const styles = {
  videoContentAreaBackground: {
   padding: "40px 0px",
  },
  titleContainer: {
   display: 'flex',
   justifyContent: lg && "center"
  },
  title: {
   display: 'inline-block',
  },
  videoContentAreaContainer: {
   display: 'flex',
   alignItems: 'center',
   padding: !lg ? "0px 10% !important" : "0px 24px !important",
   flexDirection: !lg ? 'row' : 'column',
   rowGap: 4,
   columnGap: 8,
  },
  videoContentAreaGraphic: {
   width: !lg ? '50%' : '100%',
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'center',
   columnGap: 2,
   paddingTop: !lg ? '90px' : '0px',
  },
  imageContainer: {
   width: "min-content",
  },
  videoContentAreaTexts: {
   width: !lg ? '100%' : '100%',
   textAlign: !lg ? 'right' : 'center',
   display: "table",
   position: "relative",
  },
  description: {
   padding: '35px 0px',
  },
  playpause: {
   display: "block",
   width: lg ? "100%" : "555px",
   height: "100%",
   position: "absolute",
   right: "0%",
   top: "0%",
   bottom: "0%",
   margin: "auto",
   backgroundSize: "contain",
   backgroundPosition: "center",
   opacity: "1",
   transition: "opacity 400ms",
   cursor: "pointer",
   "&[hidden]": {
    opacity: "0",
    display: "block",
   }
  },
  playIconContainer: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   width: "100%",
   height: "100%",
  },
  playIconWrapper: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   width: "64px",
   height: "64px",
   borderRadius: "100%",
   background: "#00474fc2",
  }
 }
 
 return (
  <Box sx={styles.videoContentAreaBackground}>
   <Container sx={styles.videoContentAreaContainer}>
    {/* Left side */}
    <Box>
     <Box sx={styles.titleContainer}>
      <Typography variant="h3" color="primary.dark" sx={styles.title}>{title}</Typography>
     </Box>
     <Box sx={styles.description}>
      <Typography color="text.primary">{NewlineText(description)}</Typography>
     </Box>
     <Box sx={{ textAlign: lg ? "center" : "left" }}>
      {callToAction && (
       <a href={callToAction?.link} style={{ textDecoration: 'none'}}>
        <Button variant="contained">
         {callToAction?.text}
        </Button>
       </a>
      )}
     </Box>
    </Box>
    {/* Right side */}
    <Box sx={styles.videoContentAreaTexts} className="wrapper">
     <video className="video" width={lg ? "100%" : "555px"} height="370px">
      <source src={video} type="video/mp4" />
     </video>
     <Box sx={styles.playpause} className="playpause">
      <Box sx={styles.playIconContainer}>
       <Box sx={styles.playIconWrapper}>
        <Icon name="playArrowIcon" height="60px" color={theme.palette.primary.main}/>
       </Box>
      </Box>
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { VideoContentArea }