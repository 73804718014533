import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Icon } from "src/templates"

const HowToPymes = ({title, image, items}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
   
 const styles = {
  howToPymesBackground: {
   padding: "40px 0px",
  },
  howToPymesContainer: {
   display: 'flex',
   alignItems: 'center',
   padding: !lg ? "0px 10% !important" : "auto",
   flexDirection: !lg ? 'row' : 'column',
   rowGap: !lg ? 20 : 5,
   columnGap: 5,
  },
  howToPymesImage: {
   width: !lg ? '50%' : '100%',
   textAlign: "center",
  },
  image: {
   width: "100%",
   maxWidth: "480px",
   minWidth: "172px",
  },
  howToPymesTexts: {
   width: !lg ? '50%' : '100%',
  },
  howToPymesTextItem: {
   paddingTop: "0px !important",
   mb: "32px",
   "&:last-child": {
    marginBottom: "0px !important",
   }
  },
  description: {
   padding: '35px 0px 0px',
  },
 }
 
 return (
  <Box sx={styles.howToPymesBackground}>
   <Container sx={styles.howToPymesContainer}>
    {/* Left side */}
    <Box sx={styles.howToPymesImage}>
     {image && (
      <Box component="img" sx={{...styles.image}} src={image}/>
     )}
    </Box>
    {/* Right side */}
    <Box sx={styles.howToPymesTexts}>
     <Box>
      <Typography variant="h3" color="primary.dark" align={lg ? "center" : "left"}>{title}</Typography>
     </Box>
     <Box sx={styles.description}>
      <Grid container rowSpacing={5} justifyContent="space-evenly" sx={styles.description}>
       {items.map((item, key) => (
        <Grid key={key} item sm={12} sx={styles.howToPymesTextItem}>
         <Box sx={{ display: "flex", flexDirection: 'row', justifyContent:"center", columnGap: 2}}>
          <Box>
           <Icon name={item?.icon} height="32px" color={theme.palette.primary.main} />
          </Box>
          <Box>
           <Typography component="div" color="text.primary" variant="body1">
            <Box sx={{ display: 'inline', fontWeight: 700, color:"text.primary" }}>{item?.title}. </Box>
            {item?.description}</Typography>
          </Box>
         </Box>
        </Grid>
       ))}
      </Grid>
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { HowToPymes }