import React from "react"
import { HeroSmall} from "src/templates"
import { Testimonials } from "src/components/common/Testimonials"
import { HowToPymes } from "src/components/common/HowToPymes"
import { VideoContentArea } from "../components/common/VideoContentArea";
import { SolutionsFeatures } from "../components/common/SolutionsFeatures";
import { Infographics } from "../components/common/Infographics";

// Imports para los contenidos
import { getContent } from 'src/queries';

export default function Pymes() {


 const heroPymesBannerResults = getContent("hero-pymes-banner", process.env.GATSBY_REACT_APP_REGION)
 const liquidityContentAreaResults = getContent("liquidity-content-area", process.env.GATSBY_REACT_APP_REGION)
 const howToContentAreaResults = getContent("how-to-content-area", process.env.GATSBY_REACT_APP_REGION)
 const testimonialsCarrouselResults = getContent("testimonials-carrousel-pymes", process.env.GATSBY_REACT_APP_REGION)
 const pymesSolutionsFeaturesResults = getContent("pymes-solutions-features", process.env.GATSBY_REACT_APP_REGION)
 return (
  <>
   {process.env.GATSBY_REACT_APP_REGION !== "en-US" && (
    <>
     {/* Bloque de video o imagen principal */}
     <HeroSmall
      title={heroPymesBannerResults?.title}
      body={heroPymesBannerResults?.body?.internal?.content}
      callToAction={heroPymesBannerResults?.callToAction}
      backgroundDesktop={heroPymesBannerResults?.backgroundDesktop?.file?.url}
      backgroundMobile={heroPymesBannerResults?.backgroundMobile?.file?.url}
     />
     {/* Bloque de soluciones */}
     <SolutionsFeatures
      title={pymesSolutionsFeaturesResults?.title}
      items={pymesSolutionsFeaturesResults?.features}
     />
     {/* Anticipos */}
     {process.env.GATSBY_REACT_APP_REGION === 'es-MX' && (
      <Infographics />
     )}
     {/* Bloque de nuestro propósito */}
     <HowToPymes
      title={howToContentAreaResults?.title}
      image={howToContentAreaResults?.images[0]?.file?.url}
      items={howToContentAreaResults?.icons}
     />
     {/* Bloque de video */}
     <VideoContentArea
      title={liquidityContentAreaResults?.title}
      description={liquidityContentAreaResults?.body?.internal?.content}
      video={liquidityContentAreaResults?.images[0]?.file?.url}
      callToAction={liquidityContentAreaResults?.callToAction}
     />
    </>
   )}
   {/* Bloque de testimoniales */}
   <Testimonials
    title={testimonialsCarrouselResults?.title}
    items={testimonialsCarrouselResults?.testimonials}
   />
  </>
 )
}
