// extracted by mini-css-extract-plugin
export var button = "styles-module--button--61bf0";
export var buttonText = "styles-module--buttonText--50586";
export var cardsContainer = "styles-module--cardsContainer--d7bf1";
export var cardsContainerMoreCards = "styles-module--cardsContainerMoreCards--7ca3e";
export var cardsContainerOneCard = "styles-module--cardsContainerOneCard--4d65c";
export var container = "styles-module--container--b0748";
export var generalDescription = "styles-module--generalDescription--1f72d";
export var hideButton = "styles-module--hideButton--67498";
export var iconContainer = "styles-module--iconContainer--4078c";
export var productCard = "styles-module--productCard--a8e82";
export var productDescription = "styles-module--productDescription--195a5";
export var productRequirementsList = "styles-module--productRequirementsList--cbf6a";
export var productSubtitle = "styles-module--productSubtitle--5ffd8";
export var productTitle = "styles-module--productTitle--b1eb1";
export var productsContainer = "styles-module--productsContainer--b73c7";
export var productsTitle = "styles-module--productsTitle--1cc49";
export var requirementsContainer = "styles-module--requirementsContainer--73b6c";
export var titleContainer = "styles-module--titleContainer--2d943";