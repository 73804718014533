/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Fragment } from "react"
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as styles from "./styles.module.css";
import { Button } from "src/templates";
import content from "./cardsContent.json";
import prontoPagoIcon from "./img/pronto_pago.png";
import facturaIcon from "./img/factura.png";
import riegoIcon from "./img/riego.png";
import serviuIcon from "./img/serviu.png";
import creditoDirectoIcon from "./img/credito_directo.png";


const SolutionsFeatures = ({title, items}) => {
 const country = process.env.GATSBY_REACT_APP_REGION;
 const countryProducts = content[country];

 const images = {
     prontoPagoIcon,
     facturaIcon,
     riegoIcon,
     serviuIcon,
     creditoDirectoIcon
 }
 
 return (
  <Container className={styles.container}>
   <Box>
    <Box className={styles.titleContainer}>
     <Typography color="primary.dark" variant="h3" align="center">{title}</Typography>
    </Box>
     <Box className={styles.productsContainer}>
       <Box className={[styles.cardsContainer, countryProducts?.advances.length > 1 ? styles.cardsContainerMoreCards : styles.cardsContainerOneCard]}>
         <Typography variant="h4" className={styles.productsTitle} color="primary">
           Anticipos
         </Typography>
         <Typography component="div" className={countryProducts?.advances.length > 1 ? '' : styles.generalDescription} color="text.primary" align="center" sx={{ margin: "0px 32px"}}>
           Obtén liquidez dejando una factura por cobrar o un pagaré como garantía.
         </Typography>
         {countryProducts?.advances.map((product, index) => (
             <Box key={index} className={styles.productCard}>
                 <Box>
                    <Box className={styles.iconContainer}>
                      <Box component="img" src={images[product?.icon]} />
                    </Box>
                    <Typography component="div" className={styles.productTitle} color="text.primary">
                      {product?.title}
                    </Typography>
                 </Box>
                 <Box className={styles.productDescription}>
                    <Typography>
                      {product?.description}
                    </Typography>
                 </Box>
                 <Box className={styles.requirementsContainer}>
                   <Typography className={styles.productSubtitle}>
                     {product?.subtitle}
                   </Typography>
                   <Typography className={styles.productRequirementsList}>
                     {product?.list}
                   </Typography>
                 </Box>
                 <Box>
                   <Button className={country !== "es-PE" ? styles.hideButton : styles.button} href={product?.link} variant="contained">
                     <span className={styles.buttonText}>Solicitar producto</span>
                   </Button>
                 </Box>
             </Box>
         ))}
       </Box>
       {countryProducts?.credits.length > 0 && (
       <Box className={[styles.cardsContainer, countryProducts?.advances.length > 1 ? styles.cardsContainerMoreCards : styles.cardsContainerOneCard]}>
         <Typography variant="h4" className={styles.productsTitle} color="primary">
           Créditos
         </Typography>
         <Typography component="div" className={countryProducts?.advances.length > 1 ? '' : styles.generalDescription} color="text.primary" sx={{ margin: "0px 32px"}}>
           Préstamos con condiciones y tasas convenientes, acordes a la realidad de tu empresa.
         </Typography>
         {countryProducts?.credits.map((product, index) => (
             <Box key={index} className={styles.productCard}>
                 <Box>
                    <Box className={styles.iconContainer}>
                      <Box component="img" src={images[product?.icon]} />
                    </Box>
                    <Typography component="div" className={styles.productTitle} color="text.primary">
                      {product?.title}
                    </Typography>
                 </Box>
                 <Box className={styles.productDescription}>
                    <Typography>
                      {product?.description}
                    </Typography>
                 </Box>
                 <Box className={styles.requirementsContainer}>
                   <Typography className={styles.productSubtitle}>
                     {product?.subtitle}
                   </Typography>
                   <Typography className={styles.productRequirementsList}>
                     {product?.list}
                   </Typography>
                 </Box>
                 <Box>
                   <Button className={country !== "es-PE" ? styles.hideButton : styles.button} href={product?.link} variant="contained">
                     <span className={styles.buttonText}>Solicitar producto</span>
                   </Button>
                 </Box>
             </Box>
         ))}
       </Box>
       )}
     </Box>
   </Box>
  </Container>
 )}

export { SolutionsFeatures }
